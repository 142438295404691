import React, { FormEvent, useEffect, useRef, useState } from "react";
import { isValidEmail, isValidName, sendEmail } from "../../../../utils/utils";
import ErrorSyntexWindow from "./ErrorSyntexWindow";

enum isSubmited {
  True,
  False,
  Error,
}

const Form = ({ ...props }) => {
  //ref's
  const refForm = useRef<HTMLFormElement | null>(null);
  //stat's]
  const [isSubmit, setIsSubmit] = useState<isSubmited>(isSubmited.False);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [syntaxErrors, setSyntaxErrors] = useState({
    name: false,
    email: false,
  }); //state that save if the user insert incorrect name/email

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let isError = false;
    if (!isValidName(name)) {
      syntaxErrors.name = true;
      isError = true;
    } else {
      syntaxErrors.name = false;
    }
    if (!isValidEmail(email)) {
      syntaxErrors.email = true;
      isError = true;
    } else {
      syntaxErrors.email = false;
    }
    setSyntaxErrors({ ...syntaxErrors });
    if (refForm.current && !isError) {
      try {
        await sendEmail(refForm.current);
        setIsSubmit(isSubmited.True);
      } catch {
        setIsSubmit(isSubmited.Error);
      }
    }
  };
  switch (isSubmit) {
    case isSubmited.True:
      return <MsgSend />;
    case isSubmited.Error:
      return <Error />;
    case isSubmited.False:
      return (
        <>
          <ErrorSyntexWindow
            syntaxErrors={syntaxErrors}
            setSyntaxErrors={setSyntaxErrors}
          />
          <form ref={refForm} onSubmit={(e) => onSubmit(e)}>
            <label>Name:</label>
            <br />
            <input
              type="text"
              placeholder="what's your name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              name={"user_name"}
              autoComplete={"off"}
            />
            <br />
            <label>Email</label>
            <input
              type={"text"}
              placeholder={`what's your email`}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              name={"user_email"}
              autoComplete={"off"}
            />

            <br />
            <label>Message</label>
            <textarea
              placeholder={`what's your massage`}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              name={"user_message"}
              autoComplete={"off"}
            />
            <input id={"phone-send-button"} type={"submit"} value={"Send"} />
          </form>
        </>
      );
  }
};

const Error = () => {
  return (
    <p className={"msg-reponse"}>
      sorry about inconvenience, we have problem right now please try again
      later
    </p>
  );
};

const MsgSend = () => {
  return (
    <p className={"msg-reponse"}>
      The message has been received,I will get back to you soon.
    </p>
  );
};

export default Form;
