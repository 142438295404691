import React from 'react'
import StarsCanvas from './StarsCanvas';
const App = () => {
    return (
        <div className={'canvas-background'}>
                <StarsCanvas />        
        </div>
    );
};

export default React.memo(App);