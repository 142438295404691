import React, { useEffect, useRef } from "react";
//const's
const ERROR_MSG_NAME = "enter valid name (up 3 lettes)";
const ERROR_MSG_EMAIL = "enter valid email";
const errorImg = "./images/error.png";
const ErrorSyntexWindow = ({ ...props }) => {
  //prop's
  const syntaxErrors = props.syntaxErrors;
  const setSyntaxErrors = props.setSyntaxErrors; 
  //ref's
  const refErrorMsg = useRef<HTMLDivElement | null>(null);

  //function's
  const closeErrorWindow = () => {
    if (refErrorMsg.current) {
      refErrorMsg.current.classList.remove("active");
      refErrorMsg.current.classList.add("disable");

      setSyntaxErrors({ name: false, email: false });
    }
  };
  const openErrorWindow = () => {
    if (refErrorMsg.current) {
      refErrorMsg.current.style.display = "block";
      refErrorMsg.current.classList.remove("disable");
      refErrorMsg.current.classList.add("active");
    }
  };

  useEffect(() => {
    if (syntaxErrors.name || syntaxErrors.email) {
      openErrorWindow();
    }
  }, [syntaxErrors]);

  return (
    <div ref={refErrorMsg} id="error-msg-form">
      <div id="top-block">
        <img src={errorImg} width={7} height={7} />
      </div>
      <ul>
        {syntaxErrors.email ? <li>{ERROR_MSG_EMAIL}</li> : <></>}
        {syntaxErrors.name ? <li>{ERROR_MSG_NAME}</li> : <></>}
      </ul>
      <div id="wrap-button">
        <button onClick={() => closeErrorWindow()}>OK</button>
      </div>
    </div>
  );
};
export default ErrorSyntexWindow;
