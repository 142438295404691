import { create } from "zustand";
interface ButtonProps {
  isZoomIn: boolean;
  setIsZoomIn: (isZoom: boolean) => void;
  zoomSize: number;
  setZoomInSize: (zoomSize: number)=> void;
  zoomSizeScreenFit: number;
  setZoomSizeScreenFit: (zoomSizeScreenFit: number)=> void;
}


export const useZoomIn = create<ButtonProps>()((set) => ({
    isZoomIn: false,
    setIsZoomIn : (isZoomIn) => set((state) =>({...state,isZoomIn})),
    zoomSize: 100,
    setZoomInSize: (zoomSize) => set((state) => ({...state, zoomSize})),
    zoomSizeScreenFit: 100,
    setZoomSizeScreenFit: (zoomSizeScreenFit: number)=> set((state) => ({...state, zoomSizeScreenFit})),
}))




