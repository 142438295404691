/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 ./CoverBalls.glb --types -t
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Mball010: THREE.Mesh
  }
}

const materialsProps = {
  metalness: 0,
    roughness: .2,
    color: "#3c0c4e",
}

export function CoverBalls(props: JSX.IntrinsicElements['group']) {
  const { nodes } = useGLTF('models/CoverBalls.glb') as GLTFResult
  return (
    <group {...props} dispose={null} receiveShadow castShadow>
      <mesh geometry={nodes.Mball010.geometry} >
        <meshStandardMaterial {...materialsProps} />
        </mesh>
    </group>
  )
}

useGLTF.preload('models/CoverBalls.glb')
