import React from "react";

const Logo = () => {
  const rects = Array(8)
    .fill(0)
    .map((ele, key) => {
      const raduis = 20;
      const PI = Math.PI;
      const center = 20;
      const x = center + raduis * Math.sin((2 * PI * key) / 8);
      const y = center + raduis * Math.cos((2 * PI * key) / 8);
      return (
        <rect
          id={"rect"}
          key={key}
          x={x}
          y={y}
          width="4"
          height="4"
          fill="#4e5b78"
          stroke="#141316"
          strokeWidth={1.3}
        ></rect>
      );
    });
  return (
    <div className="logo">
      <svg height="42" width="170">
        <circle
          transform="translate(9,-6) rotate(22)"
          cx="22.5"
          cy="21"
          r="19.5"
          stroke="#4e5b78"
          strokeWidth="2"
          fill="none"
        />
        {rects}
        <text id={"arrow-left"} x="11" y="28">
          {"<"}
        </text>
        <text id={"slash"} x="18" y="28">
          {"/"}
        </text>
        <text id={"arrow-right"} x={"26"} y="28">
          {">"}
        </text>
        <text id={"name"} x="50" y="20">
          NATI NADAV
        </text>
        <text id={"role"} x="50" y="40">
          FS DEVELOPER
        </text>
      </svg>
    </div>
  );
};

export default Logo;
