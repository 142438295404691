import React, { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { SpotLight } from "@react-three/drei";
import { listToVector3, createProjectorShape } from "./utils";
import { useControls } from "leva";

const boxProjectorScale = 0.2;

const spehreScreenLightPoistion = [0, 1.9, 2.6] as [number, number, number];
const spehreScreenLightScale = 0.5;

const spehreKeyboardLightPoistion = [-2, 0.5, 2.6] as [number, number, number];
const spehreKeyboardLightScale = 0.5;

const extrudeSettings = {
  depth: 5,
};

const BoxProjectorMaterial = {
  // thickness:           { value: 5, min: 0, max: 20 },
  // roughness:           { value: 0, min: 0, max: 1, step: 0.1 },
  // metalness:           { value: .9, min: 0, max: 1, step: 0.1 },
  // clearcoatRoughness:  { value: 0, min: 0, max: 1, step: 0.1 },
  // ior:                 { value: 1.6, min: 1, max: 2.3, step: 0.05 },
  // envMapIntensity:     { value: 1, min: 0, max: 100, step: 1 },
  // color:               "#b511c0",
  // attenuationTint:     "#b511c0",
  // attenuationDistance: { value: 1, min: 0, max: 1 },
  emissive:   "#7a6d7b",  //     "#7a6d7b",
  emissiveIntensity: 0.3, //   { value: 0.3, min: -10, max: 10 ,step: 0.1 },
  // specularColor:       "#b511c0",
  // specularIntensity:   { value: 0.3, min: -10, max: 10 ,step: 0.1 },
  // transparent:         false,
  // toneMapped:          false,
};

const sphereMaterials = {
  thickness: 5, // { value: 5, min: 0, max: 20 },
  roughness: 0, //{ value: 0, min: 0, max: 1, step: 0.1 },
  metalness: 0.9, //{ value: .9, min: 0, max: 1, step: 0.1 },
  clearcoatRoughness: 0, // { value: 0, min: 0, max: 1, step: 0.1 },
  ior: 1.6, // { value: 1.6, min: 1, max: 2.3, step: 0.05 },
  envMapIntensity: 1, //{ value: 1, min: 0, max: 100, step: 1 },
  color: "#b511c0",
  attenuationTint: "#b511c0",
  attenuationDistance: 1, //{ value: 1, min: 0, max: 1 },
  emissive: "#b511c0",
  emissiveIntensity: 5.3, //{ value: 5.3, min: -10, max: 10 ,step: 0.1 },
  specularColor: "#b511c0",
  specularIntensity: 0.3, // { value: 0.3, min: -10, max: 10 ,step: 0.1 },
};

const spotScreenSettings = {
  penumbra: 1,
  distance: 12,
  angle: 0.999,
  attenuation: 2,
  anglePower: 2,
  intensity: 6,
  position: [0, 1.9, 2.6] as [number, number, number],
  color: "#b511c0",
  targetPosition: new THREE.Vector3(),
};

const spotKeyboardSettings = {
  penumbra: 1,
  distance: 12,
  angle: 0.3,
  attenuation: 2,
  anglePower: 2,
  intensity: 4,
  position: [-2, 0.5, 2.6] as [number, number, number],
  color: "#b511c0",
  targetPosition: new THREE.Vector3(),
};

interface BoxProjectorProps {
    childrenWorldPositon: {screenWorldPositon : THREE.Vector3 , KeyboardWorldPositon: THREE.Vector3}
}

const BoxProjector = ({childrenWorldPositon}: BoxProjectorProps) => {
  //update the diraction of spot that point in keyboard/screen

  spotKeyboardSettings.targetPosition = childrenWorldPositon.KeyboardWorldPositon;
  spotScreenSettings.targetPosition = childrenWorldPositon.screenWorldPositon;

  //ref's
  const ref = useRef<THREE.Mesh | null>(null);

  //funciton's
  const shape = useMemo(createProjectorShape, []);
  return (
    <group scale={boxProjectorScale} name="projector">
      <Spot {...spotScreenSettings} />
      {/* <Spot {...spotKeyboardSettings} /> */}
      <mesh ref={ref}>
        <extrudeGeometry args={[shape, extrudeSettings]} />
        <meshLambertMaterial {...BoxProjectorMaterial} />
      </mesh>
      <mesh position={spehreScreenLightPoistion} scale={spehreScreenLightScale}>
        <sphereGeometry />
        <meshPhongMaterial {...sphereMaterials} />
      </mesh>
      <mesh
        position={spehreKeyboardLightPoistion}
        scale={spehreKeyboardLightScale}
      >
        <sphereGeometry />
        <meshPhongMaterial {...sphereMaterials} />
      </mesh>
    </group>
  );
};

interface SpotProps {
  penumbra?: number;
  distance?: number;
  angle?: number;
  attenuation?: number;
  anglePower?: number;
  intensity?: number;
  position?: [number, number, number];
  color?: string;
  targetPosition?: THREE.Vector3;
}

function Spot(props: SpotProps) {
  const light = useRef<THREE.SpotLight | null>(null);
  useEffect(() => {
    if (light.current) {
      const p = props.targetPosition;
      p ? light.current.target.position.copy(p) : null;
      light.current.target.updateMatrixWorld();
    }
  }, [light, props.targetPosition]);

  return <SpotLight ref={light} castShadow {...props} />;
}

export default React.memo(BoxProjector);
