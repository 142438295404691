import React from "react";
import { Phone } from "./canvas/Phone";
import { contact } from "../constants"; 

const Contact = () => {
  return (
    <section className="contact reveal" id={"contact"}>
      <h1>Get In Touch</h1>
      <div className="flex-page">
        <div className="wrap-p">
          <p>
          {contact.paragraph}
          </p>
          <Arrow />
        </div>

        <div className="wrap-phone">
          <Phone />
        </div>
      </div>
    </section>
  );
};
export default React.memo(Contact);

const Arrow = () => {
  return (
    <svg
      id={"arrow-led"}
      width="250"
      height="250"
      viewBox="-40 0 400 280"
      fill="none"
    >
      <path
        d="M262.608 188.71C99.1922 232.145 36.0001 201 12 34C-15 -1.00001 26 161 36.0001 161"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M233.544 167.57C233.544 167.57 315.944 162.356 296.2 181.93C276.456 201.505 253 228 253 228C245 252 288 201 288 201"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <filter id="SVGID_0">
        <feGaussianBlur in="SourceGraphic" stdDeviation="6.6" />
        <feOffset dx="0" dy="0" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </svg>
  );
};
