/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 Screen.glb --types -t
*/

import * as THREE from "three";
import React, {useRef } from "react";
import {Text, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useFrame } from "@react-three/fiber";
import Display from "./Display";

type GLTFResult = GLTF & {
  nodes: {
    screenMain: THREE.Mesh;
    screenBottom: THREE.Mesh;
    screenSide: THREE.Mesh;
    screenSide2: THREE.Mesh;
  };
};
const materialScreen = {
  transmission: 1, //{ value: 1, min: 0.9, max: 1, step: 0.01 },
  ior: 1.6, //{ value: 1.6, min: 1, max: 2.3, step: 0.05 },
  color: "#b511c0",
  emissive: "#b511c0",
  transparent: true
};

const brandSetting = {
  position : [-0.1, 0.99, 1.7] as [number,number,number],
  rotation : [0, -1.57, 0] as [number,number,number],
  scale: 0.2,
}

//function's

// make sceen hovering
const hoveringScreen = (position: THREE.Vector3, time: number)=>{
  const calMovementCos = Math.cos(time) / 600;
  position.y += calMovementCos;
}
//create screen flickaring
const flickerScreen = (child: THREE.Mesh,time: number)=>{
  let material = child.material as THREE.MeshPhongMaterial;
  material.emissiveIntensity = Math.abs(Math.sin(time * 40)) + 1.5;
  material.needsUpdate = true;
}

export function Screen(props: JSX.IntrinsicElements["group"]) {
  const position = [0,.9,.56] as [number,number,number];
  //ref's
  const refGroup = useRef<THREE.Group | null>(null);
  //gltf
  const { nodes } = useGLTF("/models/Screen.glb") as GLTFResult;

  useFrame((state, delta) => {
    const time = state.clock.getElapsedTime();
    if (refGroup.current) {
      const children = refGroup.current.children;
      children.forEach((child) => {
        flickerScreen((child as THREE.Mesh),time);
        hoveringScreen((child as THREE.Mesh).position,time);
      });
    }
  });

  return (
    <>
      <group
        ref={refGroup}
        receiveShadow
        {...props}
        dispose={null}
        position={position}
        name='screen'
      >
        <mesh geometry={nodes.screenMain.geometry}>
          <meshPhysicalMaterial
            {...materialScreen}
          />
          <Display />
        </mesh>
        <mesh geometry={nodes.screenBottom.geometry}>
          <meshPhysicalMaterial
            {...materialScreen}
          />
          <Text {...brandSetting}>Nati Dev</Text>
        </mesh>
        <mesh
          geometry={nodes.screenSide.geometry}
        >
          <meshPhysicalMaterial
            {...materialScreen}
          />
        </mesh>
        <mesh
          geometry={nodes.screenSide2.geometry}
        >
          <meshPhysicalMaterial
            {...materialScreen}
          />
        </mesh>
      </group>
    </>
  );
}
useGLTF.preload("/models/Screen.glb");
