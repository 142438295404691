import { Canvas } from "@react-three/fiber";
import React, { useRef } from "react";
import { Phone } from "./Phone";
import {
  Environment,
  OrbitControls,
  OrthographicCamera,
  Preload,
} from "@react-three/drei";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import useControlLoop from "../../../hooks/useControlLoop";
const enviroment = '/images/enviroment.hdr'

const App = () => {
  const ref = useRef<HTMLDivElement|null>(null)
  const frameLoop = useControlLoop(ref)
  return (
    <div className="canvas-phone" ref={ref}>
      <Canvas frameloop={frameLoop} dpr={[1,2]} performance={{min: .5}}>
        <OrthographicCamera makeDefault position={[1.3, 0.5, 2]} zoom={250} />
        <Phone />
        <OrbitControls enableZoom={false} enableDamping={false} enableRotate={false} enablePan={false} enabled={false}/>
        <Environment files={enviroment} />
        {/* <EffectComposer>
          <Bloom />  
        </EffectComposer>
      */}
        <Preload all /> 
      </Canvas>
    </div>
  );
};

export default App;
