import React from "react";
import { Tilt } from "react-tilt";
import { about } from "../constants";


const About = () => {
  return (
    <section className="about reveal" id="about">
      <p>INTRODUCTION</p>
      <h2>Overview.</h2>
      <div className="wrap-text-card">
      <p >{about.paragraph}</p>
      <div className="wrap-cards">
        <Card text={"Web Developer"} img={about.images.website} imgHeight={80} keyCard={0} />
        <Card text={"3D Developer"} img={about.images.T3D} imgHeight={100} keyCard={1} />
        <Card
          text={"Backend Devloper"}
          img={about.images.server}
          imgHeight={100}
          keyCard={2}
          />
      </div>
          </div>
    </section>
  );
};

const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 35, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.1, // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

interface SquareProps {
  text: string;
  img?: string;
  imgHeight?: number;
  keyCard: number;
}
const Card = ({ text, img, imgHeight,keyCard }: SquareProps) => {

  return (
    <Tilt options={defaultOptions} > 
    <div  className={"card"} style={{"--i" : (keyCard+1)}  as React.CSSProperties}>
      <img src={img} height={imgHeight} />
      <div className="wrap-txt">{text}</div>
    </div>
    </Tilt>
  );
};

export default About;
