import React, {Suspense, useRef} from "react";
import { Environment, OrbitControls, OrthographicCamera, Preload } from "@react-three/drei";
import { Canvas,  } from "@react-three/fiber";
import Computer from "./Computer";
import useControlLoop from "../../../hooks/useControlLoop";
import { useZoomIn } from "../../../storeManagement/computerStore";
import useFitComputerSize from "../../../hooks/useFitComputerSize";
const computerSetting = {
  position: [0, 0, -0.5] as [number, number, number],
  rotation: [0, 0, 0] as [number, number, number],
  scale: 1,
};

const App = () => {
  //ref's
  const ref = useRef<HTMLDivElement | null>(null);
  //hook's
  useFitComputerSize();
  const frameLoop = useControlLoop(ref);  
  //store managment
  const { zoomSizeScreenFit } = useZoomIn((state) => state);
  return (
    <div className="canvas-computer" ref={ref}>
      <Suspense fallback={null}>

      <Canvas
        frameloop={frameLoop}
        dpr={[1, 2]}
        performance={{min: .5}}
        >
        <OrthographicCamera
          makeDefault
          zoom={zoomSizeScreenFit}
          />
        <Computer {...computerSetting} />
        <Preload all />
      </Canvas>
          </Suspense>
    </div>
  );
};
export default App;

// const ConfigurationCanvas = () => {
//   const bloomSetting = {
//     kernelSize: 3,
//     luminanceThreshold: 0,
//     luminanceSmoothing: 0.4,
//     intensity: 0.7,
//   };

//   return (
//     <>
//       <Perf position="bottom-left" />
//       <EffectComposer disableNormalPass>
//         <Bloom {...bloomSetting} />
//       </EffectComposer>
//     </>
//   );
// };