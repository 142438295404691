import React from "react";
import { contact } from "../../../../constants";
import "./mainPage.css";
import Form from "./Form";

const MainPage = () => {
  return (
    <div className="phone-main-page">
      <Form />
      <div className="apps">
        <div className="app" id="whatsapp-app">
          <a href={contact.btnLinks.whatsapp} target="_blank">
            <img src={contact.btnImgs.whatsapp} width={8} height={8} />
          </a>
        </div>
        <div className="app" id="twitter-app">
          <a href={contact.btnLinks.twitter} target="_blank">
            <img src={contact.btnImgs.twitter} width={8} height={8} />
          </a>
        </div>
        <div className="app" id="mail-app">
          <a href={contact.btnLinks.mail} target="_blank">
            <img src={contact.btnImgs.mail} width={8} height={8} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default MainPage;
