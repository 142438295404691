import React, { Suspense, useState} from "react";
import { About, Intro, Navbar, Contact, Experience, Tech, Loading,ErrorBoundary } from "./components";
import useRevealPage from "./hooks/useRevealPage";
import { Background } from "./components/Background";


const App = () => {

  useRevealPage();
  
    
  return (
    <ErrorBoundary >
               <Suspense fallback={<Loading />}>
        <div className="app">
          <Navbar />
          <div className="wrap-pages">
          <Background />
            <Intro />
            <About />   
            <Experience />
            <Tech />
            <Contact /> 
          </div>
        </div>
      </Suspense>  
        </ErrorBoundary>
  );
};



export default App;
