import React from 'react';
import { TechBalls } from './canvas/techBalls';



const Tech = () => {
    return (
        <section className='tech reveal'>
            <h1>Tech</h1>
            <TechBalls/>
       </section>
    );
};

export default React.memo(Tech);   