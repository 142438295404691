import React, { useEffect, useState } from "react";
// hook check if the screen size chage and got smaller then maxSmallerScreenSize
const useFitScreenSize = (maxSmallerScreenSize: number) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const fitToScreen = () => {
    if (window.innerWidth < maxSmallerScreenSize) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    fitToScreen();
    window.addEventListener("resize", fitToScreen);
    return () => window.removeEventListener("resize", fitToScreen);
  }, []);

  return isSmallScreen;
};

export default useFitScreenSize;
