import React, { useEffect, useRef, useState } from "react";
import { Screen } from "./Screen";
import BoxProjector from "./BoxProjector";
import Keyboard from "./Keyboard";
import { OrbitControls } from "@react-three/drei";
import { Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { useZoomIn } from "../../../storeManagement/computerStore";

interface ComputerProps {
  position: [number, number, number];
  rotation: [number, number, number];
}

const Computer = (props: ComputerProps) => {
  const keyboardPosition = [-2.26, 0.56, 0.56] as [number, number, number];
  const ref = useRef<THREE.Group | null>(null);
  const refControl = useRef<any>(null);

  const { isZoomIn, zoomSize,zoomSizeScreenFit ,setZoomInSize} = useZoomIn((state) => state);

  const [childrenWorldPositon, setChildrenWorldPositon] = useState({
    screenWorldPositon: new Vector3(),
    KeyboardWorldPositon: new Vector3(),
  });

  useEffect(() => {
    if (ref.current) {
      const arr = ref.current.children;
      for (let i = 0; i < arr.length; i++) {
        let vec = new Vector3();
        const child = arr[i];
        child.getWorldPosition(vec);
        if (child.name == "screen") {
          childrenWorldPositon.screenWorldPositon = vec;
        } else if (child.name == "keyboard") {
          childrenWorldPositon.KeyboardWorldPositon = vec;
        }
        setChildrenWorldPositon({ ...childrenWorldPositon });
      }
    }
  }, [ref]);

  useFrame((state, delta) => {

    if (isZoomIn) {
      if(state.camera.position.y>3.01){
      state.camera.position.lerp(new Vector3(-10, 3, 0), 0.1);
      refControl.current.target.lerp(new Vector3(0, 3, 0), 0.1);
      refControl.current.update();
      }
      state.camera.zoom = zoomSize;
    } 
    else{
      if(state.camera.position.y<3.90){
      state.camera.position.lerp(new Vector3(-10, 4, 4), 0.1);
      refControl.current.target.lerp(new Vector3(0, 2, 0), 0.1);
      refControl.current.update();
      }
      setZoomInSize(zoomSizeScreenFit)
      state.camera.zoom = zoomSize;
    }

    
  });

  return (
    <group {...props} ref={ref}>
      <BoxProjector childrenWorldPositon={childrenWorldPositon} />
      <Screen />
      <Keyboard position={keyboardPosition} />
      <OrbitControls
        ref={refControl}
        enableRotate={false}
        enableZoom={false}
        enablePan={false}
        enabled={false}
        enableDamping={false}
      />
    </group>
  );
};
export default Computer;
