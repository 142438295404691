import React, { useEffect, useMemo, useRef, useState } from "react";
import { Computer } from "./canvas/Computer";
import { useZoomIn } from "../storeManagement/computerStore";

const ArrowImg = "./images/arrowZoom.png";

//const
const headText = "Hi, My Name is";
const headTextName = "Nati";
const headsubText = "I develop 3D website's, web application's, and server's";
const textZoomIn = "click here to zoom in";

const Intro = () => {

  return (
    <section className="intro reveal">
      <Computer />
      <IntroHtml/>
    </section>
  );
};

const IntroHtml = () => {
  //ref's
  const refCircle = useRef<HTMLDivElement | null>(null);
  const refHeadParam = useRef<HTMLDivElement | null>(null);
  const refVolumeZoom = useRef<HTMLInputElement | null>(null);

  //store state's
  const { isZoomIn, setIsZoomIn, setZoomInSize, zoomSize, zoomSizeScreenFit } =
    useZoomIn((state) => state);

  //state's
  const [textPrograss, setTextProgress] = useState(0);
  const [outText, setOutText] = useState(0);
  const [valueRnageZoom, setValueRangeZoom] = useState(40);

  //disapier headText when zoomIn
  useEffect(() => {
    if (refHeadParam.current && refVolumeZoom.current) {
      const refCanvasComputer = document.getElementsByClassName('canvas-computer')[0] as HTMLDivElement;
      refHeadParam.current.style.animation = isZoomIn
        ? "smoothDisapier 1s ease-in-out forwards"
        : "smoothShow  1s ease-in-out forwards";
      if (isZoomIn) {
        refCanvasComputer.style.zIndex = "1";
        refVolumeZoom.current!.classList.add("active-zoom");
        refVolumeZoom.current!.classList.remove("deactive-zoom");
      } else {
        refCanvasComputer.style.zIndex = "-1";
        refVolumeZoom.current!.classList.remove("active-zoom");
        refVolumeZoom.current!.classList.add("deactive-zoom");
      }
    }
  }, [isZoomIn]);

  useEffect(() => {
    let time: ReturnType<typeof setTimeout>;
    if (textPrograss != textZoomIn.length) {
      time = setTimeout(() => {
        setTextProgress(textPrograss + 1);
      }, 100);
    } else if (refCircle.current) {
      refCircle.current.style.display = "flex";
    }
    return () => clearTimeout(time);
  }, [textPrograss]);

  useEffect(() => {
    let time: ReturnType<typeof setTimeout>;
    if (isZoomIn && outText != "out".length) {
      time = setTimeout(() => {
        setOutText(outText + 1);
      }, 200);
    } else if (!isZoomIn && outText > 0) {
      time = setTimeout(() => {
        setOutText(outText - 1);
      }, 200);
    }
  }, [isZoomIn, outText]);

  const moveToAboutMeSection = () => {
    window.scrollTo(0, window.innerHeight - 50);
  };

  const handleOnChange = (size: number) => {
    setValueRangeZoom(size);
    const adjustRange = size - 30;
    setZoomInSize(zoomSizeScreenFit + adjustRange * 2);
  };
  return (
    <>
      <div ref={refHeadParam} className="head-page">
        <div className="line-circle">
          <span className="circle" />
          <span className="line" />
        </div>
        <div className="wrap-text">
          <h1 className="head-text">
            {headText}{" "}
            <span>
              <span className="first-letter">
                {" "}
                {headTextName.substring(0, 1)}
              </span>
              {headTextName.substring(1, headTextName.length)}
            </span>
          </h1>
          <p>{headsubText}</p>
        </div>
      </div>
      <input
        ref={refVolumeZoom}
        id="zoom-volume"
        type="range"
        min="0"
        max="100"
        value={valueRnageZoom}
        onChange={(e) => handleOnChange(parseInt(e.target.value))}
      />
      <p className="zoom-in-text">
        <span
          ref={refCircle}
          className="circle"
          onClick={() => setIsZoomIn(!isZoomIn)}
        >
          <img width={50} height={50} src={ArrowImg} />
        </span>
        <svg viewBox="0 0 380 280">
          <path
            id="curve"
            d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
          />
          {isZoomIn || outText > 0 ? (
            <>
              <line
                x1="320"
                y1="40"
                x2="360"
                y2="80"
                stroke="white"
                strokeWidth={3}
              />
              <text x="320" y="40">
                {"out".slice(0, outText)}
              </text>
            </>
          ) : (
            <></>
          )}
          <text width="300">
            <textPath xlinkHref="#curve">
              {textZoomIn.substring(0, textPrograss)}
            </textPath>
          </text>
        </svg>
      </p>

      <div className="intro-bottom">
        <div className="scroll-icon" onClick={() => moveToAboutMeSection()}>
          <span />
        </div>
      </div>
    </>
  );
};

export default Intro;
