import emailjs from "@emailjs/browser";

const emailJs = {
  PUBLIC_KEY: "eBEcmKawf_DOCKLv-",
  SERVICE_ID: "service_6h0al34",
  TEMPLATE_ID: "template_ipa5ocv",
};

const scrollTheTopOfThePage = () => {
  window.scrollTo(0, 0);
};

const sendEmail = async (form: HTMLFormElement) => {
  return new Promise((resolve, reject) => {
    emailjs
      .sendForm(
        emailJs.SERVICE_ID,
        emailJs.TEMPLATE_ID,
        form,
        emailJs.PUBLIC_KEY
      )
      .then(
        (result) => {
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
  });
};



const isValidEmail = (email: string) :boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
};

const isValidName = (name: string) :boolean =>{
    return /^[A-Za-z]{3,}$/.test(name)
}

export { scrollTheTopOfThePage, isValidEmail,isValidName,sendEmail };
