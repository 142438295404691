import Ball from "./Ball";
import { tech } from "../../../constants";
import { CoverBalls } from "./CoverBalls";
import { OrthographicCamera } from "@react-three/drei";
import React, { useMemo } from "react";

const NormalScreen = () => {
  return (
    <>
      <OrthographicCamera makeDefault position={[0, 0, 5]} zoom={21} />
      <group rotation={[0, 0, Math.PI / 8]}>
        <CoverBalls />
        <Balls />
      </group>
    </>
  );
};

export default React.memo(NormalScreen);

const Balls = () => {
  const getTechBalls = () => {
    return Object.values(tech.logoimg).map((eleLogo, key) => {
      return (
        <Ball
          key={key}
          logoTexture={eleLogo}
          position={tech.ballsPositons[key] as [number, number, number]}
          scale={tech.ballsScale[key]}
          rotation={tech.ballsRotaions[key] as [number, number, number]}
        />
      );
    });
  };
  const balls = useMemo(() => getTechBalls(), []);
  return <group>{balls}</group>;
};
