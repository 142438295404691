const sections = ['about' , 'work' , 'tech' , 'contact'];

// musr conatin is sections list
const navbar = {
    menuList: ['about' , 'work' , 'contact']
}

const about = {
   paragraph :  `I'm a Full Stack developer with experience developing servers and websites, my stack development includes frameworks like React, Node.js, Sanic, Flask, and more. also have high skills with 3D effects to turn every website more interactive with Three.js and WebGL .you have a project idea, want to improve your server\\website, or have any questions, feel free to talk with me, I'm at your service.`,
   images: {
    website: "./images/websiteImg.png",
    server : "./images/serverImg.png",
    T3D:"./images/3DImg.png",
   }

}

const expirience = [
    {
        date: "2017-2020",
        company: "Ariel University",
        img: './images/experience/arielImg.png',
        description: 
                    `graduate B.Sc. in computer science & Mathematics | Ariel University 
                    final project- Web Application combined with IoT the purpose of the
                    project is to recognize people by a camera and identify them, if there
                    are authorized then allow to enter to the homepage.`,
        fullDescription: `graduate B.Sc. in computer science & Mathematics | Ariel University 
                          final project- Web Application combined with IoT the purpose of the
                          project is to recognize people by a camera and identify them, if there
                          are authorized then they can enter to the homepage. Server-side: Server
                          built with Node-Red, the connection between the camera was used by MQTT
                          protocol, and the communication in the frontend side was by React.
                          Hardware-side: OpenMV Cam is like a super-powerful Arduino with a camera
                          onboard that you program in Python. Easy to run machine visions
                          algorithms on what the OpenMV Cam sees so you can track colors, detect
                          faces and more in seconds and then control I/O pins in the real world.`
    },
    {
        date: "2021",
        company: "Dxc Course",
        img: './images/experience/dxcImg.png',
        description: `Intensive Full-Stack Web Development Course that provided
                      me with a comprehensive understanding of both front-end and back-end web
                      development. This immersive program covered a wide range of technologies
                      and tools, including HTML, CSS, JavaScript, Node.js, MongoDB, and more.
                      Throughout the course, I worked on a variety of real-world projects and
                      assignments that allowed me to apply my knowledge and skills in practical
                      settings.`,
        fullDescription: 
                    `Intensive Full-Stack Web Development Course that provided
                    me with a comprehensive understanding of both front-end and back-end web
                    development. This immersive program covered a wide range of technologies
                    and tools, including HTML, CSS, JavaScript, Node.js, MongoDB, and more.
                    Throughout the course, I worked on a variety of real-world projects and
                    assignments that allowed me to apply my knowledge and skills in practical
                    settings. I learned how to design and build responsive and interactive
                    user interfaces, create and manage databases, write server-side code, and
                    integrate third-party services and APIs. The course was designed to be
                    intensive and immersive, with a mix of lectures, workshops, and
                    project-based learning. I collaborated with other students to develop
                    full-stack applications from scratch, gaining valuable experience working
                    in a team environment. Overall, this Full-Stack Web Development Course
                    provided me with a strong foundation in both front-end and back-end
                    development, and prepared me for a career in web development.`
    },

    {
        date: "2021",
        company: "freelancer",
        img: './images/experience/freelanceImg.png',
        description: `Working as a freelancer, I offer a wide range of
                      skills and experience in web application development. With a focus on both
                      the server-side and client-side of web development, I am able to provide comprehensive
                      solutions to meet the needs of my clients. On the server-side, I have experience
                      working with a variety of programming languages and frameworks.`,
        fullDescription: `As a Full Stack Developer working as a freelancer, I offer a wide range of
                    skills and experience in web application development. With a focus on both
                    the server-side and client-side of web development, I am able to provide comprehensive
                    solutions to meet the needs of my clients. On the server-side, I have experience
                    working with a variety of programming languages and frameworks, including Python,
                    Node.js, Ruby on Rails, and more. I am able to develop high-performance servers
                    that can handle heavy traffic and complex calculations, and I have experience
                    deploying and maintaining servers on cloud platforms such as AWS and Azure.
                    On the client-side, I am skilled in working with a range of front-end frameworks
                    such as React, Vue.js, and Angular. I have experience building responsive and
                    user-friendly interfaces using modern web technologies such as HTML5, CSS3, and
                    JavaScript. I also have experience working with various libraries and tools, such
                    as Redux, Threejs, and Apollo As a freelancer, I am able to work on projects of all sizes
                    , from small websites to complex web applications.`
    },
    { 
        date: "2022",
        company: "Enigma Securties", 
        img: "./images/experience/enigmaImg.png",
        description: `As a Full Stack Developer at Enigma Securities, I was part of the research team tasked
                      with exploring and developing features relevant to the finance world. My role involved
                      working on both the server-side and client-side of web applications. On the server-side,
                      I was responsible for developing servers with high performance that could handle heavy
                      connections as sockets and calculations relevant to the finance world.`,
        fullDescription:
                    `As a Full Stack Developer at Enigma Securities, I was part of the research team tasked
                     with exploring and developing features relevant to the finance world. My role involved
                    working on both the server-side and client-side of web applications. On the server-side,
                    I was responsible for developing servers with high performance that could handle heavy,
                    connections as sockets, and calculations relevant to the finance world. I worked with
                    Python and JS server and was able to upload and maintain servers on EC2. On the client-side
                    , I was responsible for creating widgets relevant to the finance world, working with React
                    and all the necessary libraries, such as Redux, Styled-Components, WebSocket, Axios, JWT 
                    and more. I also utilized libraries such as ChartIQ to develop high-level graphs for our
                    applications. During my time at Enigma Securities, I was able to make significant contributions to the
                    development of various features and widgets that enhanced the user experience of our applications.
                    I utilized my expertise in both server-side and client-side development to ensure that our applications
                    were efficient, reliable, and user-friendly. verall, my experience as a Full Stack Developer
                    at Enigma Securities allowed me to develop a strong set of technical skills in server-side
                    and client-side development, and I was able to apply these skills to create innovative
                    solutions for the finance world.`
    }]

// every texture need add the type here
type  textureTypes = 'football' | 'basketball' | 'golfball';
const tech = {
normalTexture : {
    football: './images/techBalls/typeBall/footBallnormalImg.jpg',
    basketball: './images/techBalls/typeBall/basketballNormalImg.jpg',
    golfball:  './images/techBalls/typeBall/golfballNormal.jpg',
}
,
RoughnessTexture : {
    football :  './images/techBalls/typeBall/footballRoughImg.jpg',
    basketball: './images/techBalls/typeBall/basketballRougheImg.jpg',
    golfball:  './images/techBalls/typeBall/golfballRough.jpg',
}
,
logoimg : {
    sanic:  './images/techBalls/logos/sanic.jpg',
    css:  './images/techBalls/logos/css.jpg',
    docker:  './images/techBalls/logos/docker.jpg',
    git:  './images/techBalls/logos/git.jpg',
    html:  './images/techBalls/logos/html.jpg',
    node:  './images/techBalls/logos/node.jpg',
    python:  './images/techBalls/logos/python.jpg',
    react:  './images/techBalls/logos/react.jpg',
    three:  './images/techBalls/logos/three.jpg',
    ts:  './images/techBalls/logos/ts.jpg',
},

ballsPositons:
[
   [-9.47,-2.24,0],
   [-.25,-2.9,0],   
   [-15.75,1.6,0],                     
   [4.61,-1.92,0],            
   [9.39,-3.7,0],            
   [-5.73,3.24,0],                  
   [13.9,0,0],                
   [-.5,1.5,0],                
   [4.24,3.49,0],                
   [9.47,3.21,0],        
],
ballsRotaions:
[
    [0,0,0],
    [0,0,0],
    [0,0,0],
    [0,0,0],
    [0,0,0],
    [0,0,0],
    [0,0,0],
    [0,0,0],
    [0,0,0],
    [0,0,0],                    
],
ballsScale:
[
    3.49,
    1.55,
    2.40,                    
    2.05,          
    2.20,         
    2.50,                  
    2.20,           
    2,           
    1.98,             
    2,     
],


}


const textInFronWhatsapp = 'Hey, Nati how are you, My Name is {"your name"} and I will be glad to talk.'
const contact = {
    paragraph: `my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!` ,
    btnImgs : {
        twitter: './images/twitter.png',
        whatsapp: './images/whatsapp.png',
        mail: './images/mail.png',
        form: './images/contactForm.png'
    },
    btnLinks: {
        twitter: 'https://twitter.com/NatiNadav',
        whatsapp: 'https://wa.me/972527031615'+'?text='+encodeURIComponent(textInFronWhatsapp),
        mail: 'mailto:nnati070@gmail.com',
    },
    EmgTextures:{
        coolEmj: './images/contact/coolEmj.jpg',
        funnyEmj: './images/contact/funnyEmj.jpg',
        loveEmj: './images/contact/loveEmj.jpg',
        nerdEmj: './images/contact/nerdEmj.jpg',
        niceEmj: './images/contact/niceEmj.jpg',
    }
    






}

export {expirience,sections,navbar,textureTypes,tech,contact,about}