import React, { useEffect, useState } from "react";

const useControlLoop = (ref : React.RefObject<any>) => {
  const [frameLoop, setFrameLoop] = useState<"demand" | "always">("always");

  const adjustControlLoopByReveal = () => {
    if(ref.current){
    // var elementTop = document.body.getBoundingClientRect().top;
    const elementBounding = ref.current.getBoundingClientRect();
    var elementVisible = 250;
    if (elementVisible < elementBounding.top+elementBounding.height && elementBounding.top < elementVisible*2) {
      setFrameLoop("always");
    } else {
      setFrameLoop("demand");
    }
  }
  };

  useEffect(()=>{
    if(ref.current){
      addEventListener("scroll" ,adjustControlLoopByReveal)
      return ()=>removeEventListener("scroll" ,adjustControlLoopByReveal )
    }
  },[])


  return frameLoop;
};

export default useControlLoop;
