import React, { useMemo } from "react";
import { expirience } from "../constants";

interface WorkProps {
  side: "left" | "right";
  img: string;
  date: string;
  description: string;
  company: string;
}
const Work = ({ side, img, date, description,company }: WorkProps) => {
  return (
    <div className="work">
      <span className="circle" > <img src={img}/></span>
      <div className={`description-${side}`}>
          {description}
        <span />
      </div>
      <label className={`date-${side}`}> {date} </label>
      <label className={`company-${side}`}>{company}</label>
    </div>
  );
};

const getWorks = ()=>{
  return expirience.reverse().map((ele,key)=>{
    const isEven = key%2 == 0;
    return (isEven) ? <Work key={key} side="left"  {...ele} /> : <Work key={key} side="right" {...ele}/>
  })
}

const Experience = () => {
  const works = useMemo(getWorks,[]);
  return (
    <section className="experience reveal" id="work">
      <div className="head">
        <h2>Work Experience.</h2>
      </div>
      <div className="timeline">
        <span className="line" />
        <div className="list-works">
        {works}
        </div>
      </div>
    </section>
  );
};

export default Experience;
