import { textureTypes} from '../../../constants';
import { Mesh, RepeatWrapping, Vector2, Vector3 } from 'three';
import {useTexture } from '@react-three/drei';
import React, {useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';

interface BallProps{
    type?: textureTypes
    logoTexture: string;
    position: [number,number,number];
    rotation: [number,number,number];
    scale: number
    }

const getTextureBall = (type: textureTypes ,logoTexture: string)=>{
    // texture logo
    const logo = useTexture(logoTexture)
    logo.repeat = new Vector2(2,1)
    logo.wrapS = RepeatWrapping;
    return logo
}

const Ball = ({type,logoTexture,position,scale,rotation} : BallProps)=>{
    const ref = useRef<null|Mesh>(null);
    const [isHover,setIsHover] = useState(false);

    useFrame((state,delta)=>{
        if(ref.current){
            const ball = ref.current;
            const moveBall =state.clock.getElapsedTime()
            // ball.rotation.x = moveBall*scale
            ball.rotation.y = moveBall/2
            if(isHover){
                const vec = new Vector3().set(position[0],position[1],position[2])
                vec.z += 1;
                ball.position.lerp(vec,0.1);
                ball.rotation.set(rotation[0],rotation[1],rotation[2]);
            }
            if(isHover){
            }
            else{
                const vec = new Vector3().set(position[0],position[1],position[2])
                ball.position.lerp(vec,0.1);
            }
        }
    })

    const logo = getTextureBall(type as textureTypes,logoTexture) 
    return(<mesh ref={ref} rotation={rotation} position={position} scale={scale} onPointerEnter={()=>setIsHover(true)} onPointerLeave={()=>setIsHover(false)}>
        <sphereGeometry args={[1]} />
        <meshStandardMaterial map={logo} metalness={.5} roughness={.1} />
    </mesh>)
}

export default Ball