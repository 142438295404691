import React from "react";
import "./keyBoardKeys.css";

const KeyBoardKeys = () => {
  return (
    <div className="keyboard-main">
      <div className="row zero">
        <span>Esc</span>
        <span>F1</span>
        <span>F2</span>
        <span>F3</span>
        <span>F4</span>
        <span>F5</span>
        <span>F6</span>
        <span>F7</span>
        <span>F8</span>
        <span>F9</span>
        <span>F10</span>
        <span>F11</span>
        <span>F12</span>
        <span>Insect</span>
        <span className="print">printScr</span>
        <span>Delete</span>
      </div>

      <div className="row one">
        <span>~</span>
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
        <span>6</span>
        <span>7</span>
        <span>8</span>
        <span>9</span>
        <span>10</span>
        <span>-</span>
        <span>=</span>
        <span className="back">Backspace</span>
        <span>Home</span>
      </div>

      <div className="row two">
        <span className="tab">Tab</span>
        <span>Q</span>
        <span>W</span>
        <span>E</span>
        <span>R</span>
        <span>T</span>
        <span>Y</span>
        <span>U</span>
        <span>I</span>
        <span>O</span>
        <span>P</span>
        <span>{"{"}</span>
        <span>{"}"}</span>
        <span className="slace">\</span>
        <span>Pg Up</span>
      </div>

      <div className="row three">
        <span className="cap">Caps Lock</span>
        <span>A</span>
        <span>S</span>
        <span>D</span>
        <span>F</span>
        <span>G</span>
        <span>H</span>
        <span>I</span>
        <span>J</span>
        <span>K</span>
        <span>L</span>
        <span>;</span>
        <span>"</span>
        <span className="enter-btn">Enter</span>
        <span>Pg Dn</span>
      </div>

      <div className="row four">
        <span className="shift">Shift</span>
        <span>Z</span>
        <span>X</span>
        <span>C</span>
        <span>V</span>
        <span>B</span>
        <span>N</span>
        <span>M</span>
        <span> {">"} </span>
        <span>{"<"}</span>
        <span>?</span>
        <span className="shift">Shift</span>
        <span>↑</span>
        <span>End</span>
      </div>

      <div className="row five">
        <span className="last">Ctrl</span>
        <span className="last">Fn</span>
        <span className="last">Win</span>
        <span className="last">Alt</span>
        <span className="space">space</span>
        <span className="last">Alt</span>
        <span className="last">Fn</span>
        <span className="last">Ctrl</span>
        <span className="last">←</span>
        <span className="last">↓</span>
        <span className="last">→</span>
      </div>
    </div>
  );
};

export default KeyBoardKeys;
