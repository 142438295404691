import React, { Suspense, useRef } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import {Environment, Preload} from "@react-three/drei";

import SmallWidthScreen from "./SmallWidthScreen";
import NormalScreen from "./NormalScreen";
import useFitScreenSize from "../../../hooks/useFitScreenSize";
import useControlLoop from "../../../hooks/useControlLoop";

const EnvironmentImg = "./images/enviroment.hdr";

const MAX_SMALL_SCREEN_SIZE = 1000;

const App = () => {
  //ref's
  const ref = useRef<HTMLDivElement|null>(null);
  //hook's
  const frameLoop =  useControlLoop(ref)
  const isSmallScreen = useFitScreenSize(MAX_SMALL_SCREEN_SIZE);

  return (
    <div className="canvas-tech-balls" ref={ref}>
      <Suspense fallback={null}>
      <Canvas frameloop={frameLoop} dpr={[1,2]} >
        {isSmallScreen ? <SmallWidthScreen /> : <NormalScreen /> }
        <Environment files={EnvironmentImg} />
        <Preload all/>
      </Canvas>
      </Suspense>
    </div>
  );
};

export default React.memo(App);