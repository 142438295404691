import React, { useMemo, useRef } from "react";
import { OrthographicCamera } from "@react-three/drei";
import { Group } from "three";
import { tech } from "../../../constants";
import Ball from "./Ball";

const SmallWidthScreen = () => {
  return (
    <>
      <OrthographicCamera makeDefault position={[0, 0, 5]} zoom={45} />
      <group rotation={[0, 0, 0]} position={[0, -3.2, 0]}>
        <BallSmallScreen />
      </group>
    </>
  );
};
export default React.memo(SmallWidthScreen);

const BallSmallScreen = () => {
  const refBalls = useRef<Group | null>(null);
  const getTechBalls = () => {
    return Object.values(tech.logoimg).map((eleLogo, key) => {
      return (
        <Ball
          key={key}
          logoTexture={eleLogo}
          position={[2.2 * (key % 3) - 2, 2.2 * Math.floor(key / 3), 0]}
          scale={1}
          rotation={[0, -Math.PI / 2, 0]}
        />
      );
    });
  };
  const balls = useMemo(() => getTechBalls(), []);
  return <group ref={refBalls}>{balls}</group>;
};
