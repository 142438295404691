import React, { useEffect, useState } from "react";
import { useZoomIn } from "../storeManagement/computerStore"; 
const useFitComputerSize = () => {
  const {setZoomSizeScreenFit} = useZoomIn((state)=>state)
  const fitScreenSize = () => {
    if (window.innerWidth < window.innerHeight) {
      if (window.innerWidth < 500){
       setZoomSizeScreenFit(window.innerWidth / 5);
      }  
      else if (window.innerWidth < 800) {
        setZoomSizeScreenFit(window.innerWidth / 7);
      } else if (window.innerWidth < 1100) {
        setZoomSizeScreenFit(window.innerWidth / 9);
      }
    } else {
      if (window.innerHeight > 800) {
        setZoomSizeScreenFit(window.innerHeight / 8);
      }
    }
  };

  useEffect(() => {
    fitScreenSize();
    window.addEventListener("resize", fitScreenSize);
    return () => {
      window.removeEventListener("resize", fitScreenSize);
    };
  }, []);
  return null;
};

export default useFitComputerSize;
