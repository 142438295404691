import React, { useMemo } from "react";
import "./loading.css";

const Loading = () => {
  const numOfCircls = 8;

  // create the squre's in cirlcle position's
  const getSquresOfSpan = () => {
    return [...Array(numOfCircls).keys()].map((key) => {
      return <span key={key} style={{ "--i": key } as React.CSSProperties} />;
    });
  };
  const squresOfSpan = useMemo(getSquresOfSpan, []);

  const cl = (Math.PI * 2 * 53) / 7.9;
  const line = (65 * cl) / 100;
  const sapceLine = cl - line;

  return (
    <div className="main-loading">
      <div>
        <div className="logo">
          <div className="squres">{squresOfSpan}</div>
          <div className="circles-eighth">
            <svg height="120" width="120">
              <circle
                cx="58"
                cy="58"
                r="54"
                stroke="#ffffff"
                strokeWidth="4"
                strokeDasharray={`${line} ${sapceLine}`}
                fill="none"
              />
            </svg>
          </div>
          <div className="middle">
            <span className="leftArrow">{`<`}</span>
            <span className="slash">{`/`}</span>
            <span className="rightArrow">{`>`}</span>
          </div>
        </div>
        <div className="wrap-progress">
          <div className="progress">
          <div className="squres-progress">
            <span className="squre-progress" />
            <span className="squre-progress" />
            <span className="squre-progress" />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
