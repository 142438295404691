import { Vector3 , Shape } from "three"

const listToVector3 = (ls: [number,number,number])=>{
    return new Vector3(ls[0],ls[1],ls[2]);
}

//return json time of year,mounth,day,hour,second
const getCurrentDateTime = ()=>{
    let currentDate = new Date();
    let year = currentDate.getFullYear().toString();
    let mounth = (currentDate.getMonth()+1).toString();
    let day = currentDate.getDate().toString();
    let hour = currentDate.getHours().toString();
    let min = currentDate.getMinutes().toString();
    let second = currentDate.getSeconds().toString();

    if(mounth.length < 2) mounth = "0" + mounth;
    if(day.length < 2) day = "0" + day;
    if(hour.length < 2) hour = "0" + hour;
    if(min.length < 2) min = "0" + min;
    if(second.length < 2) second = "0" + second;
    
    return {year,mounth,day,hour,min,second}
}

const createProjectorShape = ()=>{
    const shape = new Shape();
    shape.moveTo(3,0).
    lineTo(-3,0).
    lineTo(-1,2).
    lineTo(1,2).
    lineTo(3,0);
    return shape
}

const createKeyboardShape = ()=>{
    const shape = new Shape()
    shape.moveTo(2,0).
    lineTo(-2,0).
    bezierCurveTo(-3,0,-3,2,-2,2)
    .lineTo(2,2).
    bezierCurveTo(3,2,3,0,2,0);
    return shape
}




export {listToVector3 , getCurrentDateTime ,createProjectorShape,createKeyboardShape}