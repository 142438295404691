import React, { useMemo } from "react";
import { useRef, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Points, PointMaterial, Preload } from "@react-three/drei";

const numOfParticles = 12000;

const createPracticals = (): Float32Array => {
  const dimension = 3;
  const pointsPosition = new Float32Array(numOfParticles);
  const radius = 10;
  for (let i = 0; i < numOfParticles; i++) {
    pointsPosition[i * dimension] = Math.random() * radius - radius / 2;
    pointsPosition[i * dimension + 1] = Math.random() * radius - radius / 2;
    pointsPosition[i * dimension + 2] = Math.random() * radius - radius / 2;
  }
  return pointsPosition;
};

const Stars = () => {
  //ref's
  const ref = useRef<THREE.Group | null>(null);
  //function's
  const sphere: Float32Array = useMemo(() => createPracticals(), []);

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.rotation.x -= delta / 10;
      ref.current.rotation.y -= delta / 15;
    }
  });
  return (
    <group ref={ref} rotation={[0, 0, Math.PI / 4]} dispose={null}>
      <Points positions={sphere} stride={3} frustumCulled>
        <PointMaterial
          transparent
          color="#9a3ad6"
          size={0.0017}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
};

const StarsCanvas = () => {
  return (
    <div className="canvas-background">
      <Canvas camera={{ position: [0, 0, 1] }} dpr={[1,2]}>
      <Suspense fallback={null}>
          <Stars />
      </Suspense>
      <Preload all/>
      </Canvas>
    </div>
  );
};

export default StarsCanvas;
