import React, { useState } from "react";
import { scrollTheTopOfThePage } from "../utils/utils";
import { sections,navbar } from "../constants";
import Logo from "./Logo";
const LogoImg = "images/logo.png";

const Navbar = () => {
  const linkTo = (key: number)=>{
    const sections = (document.getElementsByTagName("section"))
    console.log(sections)
    if(sections[key]){
      window.scrollTo(0,sections[key+1].offsetTop)
    }
  }

  const menuListJSX = sections.map((ele, key) => {
      if(navbar.menuList.includes(ele)){
      return (
        <li key={key} onClick={() => linkTo(key)}>
          {ele.slice(0,1).toUpperCase()+ele.slice(1)}
        </li>
      );
    }
  });

  return (
    <nav className="nav-bar">
      <div className="left-side-nav">
        <div onClick={() => scrollTheTopOfThePage()}>
            <Logo />
        </div>
      </div>
      <div className="right-side-nav">
        <input id="menu-toggle" type={"checkbox"} />
        <label className="menu-container" htmlFor="menu-toggle">
          <span className="first-line-menu" />
          <span className="second-line-menu" />
          <span className="third-line-menu" />
        </label>
        <ul className="list-menu">
                {menuListJSX}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
