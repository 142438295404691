import * as THREE from "three";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Html, Line, Text,  } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

import Loading from "./pages/Loading";

const TwitterPage = React.lazy(() => import("./pages/TwitterPage"));
const MainPage = React.lazy(() => import("./pages/MainPage"));
const CodeEditor = React.lazy(() => import("./pages/CodeEditor"));


const TIME_LOAD_SCREEN_REPRESET_MS = 6000;

// const x = useControls({x: {value: -.01 , min: -10, max: 10 , step: 0.01},
//   y: {value: 3.57 , min: -10, max: 10 , step: 0.01},
//   z: {value: -2.17 , min: -10, max: 10 , step: 0.01},})

const displayPosition = [-.1,2.25,0] as [number,number,number];
const displayRotation = [0,-1.57,0] as [number,number,number];
const displayScale = .55


const Display = () => {
  const [displayPage, setDisplayPage] = useState("");
  const [isStart, setStart] = useState(true);
  useEffect(() => {
    let time: ReturnType<typeof setTimeout>;
    const delayEffect = async ()=>{
      if (isStart) {
        time = setTimeout(async () => {
          setStart(false);
        }, TIME_LOAD_SCREEN_REPRESET_MS);
      }
    }
    delayEffect();
    return () => clearTimeout(time);
  }, [isStart]);

  const setPage = (page: string) => {
    switch (page) {
      case "twitter":
        return <TwitterPage />;
      case "3DWeb":
        return <iframe src="http://websiteboard.natinadav.com/" />;
      case "chrome":
        return <iframe id={'iframe-google'} src="https://www.google.com/search?igu=1"/>;
      case "codeEditor":
        return <CodeEditor />;
      default:
        if (isStart) {
          return <Loading />;
        }
        return <MainPage setPage={setDisplayPage} />;
    }
  };

  const display = useMemo(() => setPage(displayPage), [displayPage, isStart]);

  return (
    <>
      <BackButton
        isVisible={displayPage.length != 0 ? true : false}
        setMainPage={setDisplayPage}
      />
      <Html
        className="content"
        rotation={displayRotation}
        position={displayPosition}
        scale={displayScale}
        transform
        style={{ opacity: 0.5 }}
      >
        <div className="wrapper" onPointerDown={(e) => e.stopPropagation()}>
          <Suspense >{display}</Suspense>
        </div>
      </Html>
    </>
  );
};

interface BackButtonProps {
  isVisible: boolean;
  setMainPage: (page: string) => void;
}

const BackButton = ({ isVisible, setMainPage }: BackButtonProps) => {
  //ref's
  const refArrow = useRef<null | any>(null);
  const refButton = useRef<null | THREE.Group>(null);

  const positonButton = [-0.15, 3.5, 1.5] as [number,number,number];
  const rotationButton = [0, -Math.PI / 2, 0] as [number,number,number];
  const scaleButton = .15;

  const backTextPostion = [2, 0, 0] as [number,number,number]
  
  const scaleArrow = .4;
  const pointsArrow = [[0, 0, 0],[-1, 1, 0],[0, 2, 0]] as [number,number,number][];
  const positionArrow = [-1,-.4,0] as [number,number,number];
  const widthArrow = 2;
  const colorArrow = 'white'

  useEffect(() => {
    if (refButton.current) {
      refButton.current.visible = isVisible ? true : false;
    }
  }, [isVisible]);

  //prettier-ignore
  const handleHover = (isHover: boolean) => {
    const canvasDiv = document.getElementsByClassName("canvas-computer")[0] as HTMLDivElement;
    canvasDiv.style.cursor = isHover ? "pointer" : "";
  };

  const handleClick = () => {
    setMainPage("");
  };

  useFrame((state, delta) => {
    if (refArrow.current) {
      refArrow.current.position.x +=
        Math.sin(state.clock.getElapsedTime() * 2) / 100;
    }
  });
  return (
    <>
      <group
        ref={refButton}
        position={positonButton}
        rotation={rotationButton}
        scale={scaleButton}
        onPointerEnter={() => handleHover(true)}
        onPointerLeave={() => handleHover(false)}
        onClick={() => handleClick()}
        visible={false}
      >
        <Text position={backTextPostion}>Back</Text>
        <Line
          ref={refArrow}
          scale={scaleArrow}
          points={pointsArrow}
          color={colorArrow}
          lineWidth={widthArrow}
          position={positionArrow}
        />
      </group>
    </>
  );
};

export default Display;