/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 ./Phone.glb --types -t
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { Html, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import MainPage from "./pages/MainPage";
import { useFrame } from "@react-three/fiber";

const speakerImg = "./images/speaker.jpg";

type GLTFResult = GLTF & {
  nodes: {
    screen: THREE.Mesh;
    top: THREE.Mesh;
    buttom: THREE.Mesh;
    sphere: THREE.Mesh;
    speaker: THREE.Mesh;
  };
  materials: {};
};

const materialCamera = {
  roughness: 0.1, //  {value: 0.1, min: 0, max: 1, step: 0.1 },
  transmission: 0.9, //  { value: 1, min: 0.9, max: 1, step: 0.01 },
  thickness: 2.3, //  { value: 2.30, min: 1, max: 2.3, step: 0.05 },
  color: "#000000",
};

const materialSpeaker = {
  roughness: 0.1, // {value: 0.1, min: 0, max: 1, step: 0.1 },
  metalness: 0.12, // { value: 0.12, min: -1, max: 1, step: 0.01 },
  color: "#bebebe",
};
const materialScreenProps = {
  //
  thickness: 0, //{ value: 0, min: 0, max: 20 }, //?
  roughness: 0.5, //{value: 0.5, min: 0, max: 1, step: 0.1 },
  transmission: 1, //{ value: 1, min: 0.9, max: 1, step: 0.01 },
  ior: 1.45, //{ value: 1.45, min: 1, max: 2.3, step: 0.05 },
  attenuationDistance: 0.4, //{ value: 0.40, min: 0, max: 1 },
  emissive: new THREE.Color("#000000"), // "#a944c5"
  specularColor: new THREE.Color("#ffffff"), // "#ffffff"
  specularIntensity: -4, //{ value: -4.0, min: -10, max: 10, step: 0.1 },
  transparent: true, // true
  toneMapped: false, // false
  // envMapIntensity:                               //    { value: 1, min: 0, max: 100, step: 1 }
};
const sidesMaterial = new THREE.MeshStandardMaterial({
  roughness: 0.1,
  metalness: 0.55,
  color: new THREE.Color("#a344ae"),
});
const spekerMaterial = new THREE.MeshBasicMaterial({
  color: new THREE.Color("#635a63"),
  side: THREE.BackSide,
});
const cameraMatetial = new THREE.MeshBasicMaterial({
  color: new THREE.Color("#ffffff"),
  side: THREE.BackSide,
});
const screenMaterial = new THREE.MeshPhysicalMaterial(materialScreenProps);

export function Phone(props: JSX.IntrinsicElements["group"]) {
  const { nodes } = useGLTF("models/Phone.glb") as GLTFResult;
  const refScreen = useRef<THREE.Mesh | null>(null);
  useFrame((state, delta) => {
    if (refScreen.current) {
      const isPageActive = document
        .getElementsByClassName("contact")[0]
        .classList.contains("active");
      const material = refScreen.current.material as THREE.MeshPhysicalMaterial;
      isPageActive
        ? material.emissive.lerp(new THREE.Color("#a944c5"), 0.01)
        : material.emissive.set(new THREE.Color("#000000"));
    }
  });
  return (
    <group {...props} position={[0, 0.2, 0]} dispose={null}>
      <mesh
        ref={refScreen}
        geometry={nodes.screen.geometry}
        material={screenMaterial}
      >
        <Html className="phone-html" transform occlude={"blending"}>
          <MainPage />
        </Html>
      </mesh>
      <mesh geometry={nodes.top.geometry} material={sidesMaterial} />
      <mesh geometry={nodes.buttom.geometry} material={sidesMaterial} />
      <mesh
        geometry={nodes.sphere.geometry}
        material={cameraMatetial}
        position={[0.36, 0.91, 0.02]}
      />

      <mesh
        geometry={nodes.speaker.geometry}
        position={[0, 0.9, 0]}
        material={spekerMaterial}
      />
    </group>
  );
}

useGLTF.preload("models/Phone.glb");
