import { useEffect } from "react";


const useRevealPage = ()=>{
    const PageLocation = ()=>{
        const reveals = document.querySelectorAll('.reveal') as unknown as HTMLCollectionOf<HTMLElement>;
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
  
      useEffect(()=>{
        addEventListener("scroll" ,PageLocation)
        return ()=>removeEventListener("scroll" ,PageLocation )
      },[])
    return null
}
export default useRevealPage