import { Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useMemo, useRef } from "react";
import * as THREE from "three";
import KeyBoardKeys from "./pages/KeyBoardKeys";
import { createKeyboardShape } from "./utils";
import { useControls } from "leva";
const extrudeSettings = {
  depth: 0.1,
  bevelEnabled: false,
};
const matrialKeyboard = {
  transmission: 1, //{ value: 1, min: 0.9, max: 1, step: 0.01 },
  // ior: 1.6, //{ value: 1.6, min: 1, max: 2.3, step: 0.05 },
  color: "#b511c0",
  emissive: "#b511c0",
};

const keyboardProps = {
    rotation: [1.57, Math.PI/8, 1.57] as [number, number, number],
    scale: .55
}


const Keyboard = ({ ...props }) => {

  const ref = useRef<THREE.Mesh | null>(null);
  const keysProps = {
      position: [-1.2,0.78,0.05]  as [number, number, number],
      rotation: [3.14, 0, 0] as [number, number, number],
      scale: 0.18
  }
  const shape = useMemo(createKeyboardShape, []);
  useFrame((state, delta) => {
    if (ref.current) {
      const time = state.clock.getElapsedTime();
      const material = ref.current.material as THREE.MeshPhongMaterial;
      //flicker emissive
      material.emissiveIntensity = Math.abs(Math.sin(time * 20)) + 1.5;
      //fly postion
      ref.current.position.x = Math.sin(time) / 8;
      ref.current.position.y = Math.sin(time) / 8;
    }
  });
  return (
    <group {...props} name='keyboard'>
      <mesh ref={ref} {...keyboardProps}>
        <extrudeGeometry args={[shape, extrudeSettings]} />
        <meshPhysicalMaterial {...matrialKeyboard} transparent={true} />
        <Html
          className="keyboard-main"
         {...keysProps}
          transform
          style={{ opacity: 0.5 }}
        >
          <KeyBoardKeys />
        </Html>
      </mesh>
    </group>
  );
};

export default Keyboard;
